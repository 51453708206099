import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    loaded: false,
    account: null,
    provider: null,
    totalSupply: 0,
    cost: 0,
    presaleCost: 0,
    error: false,
    errorMsg: "",
    whitelisted: false,
  };

export const blockchainSlice = createSlice({
    name: 'blockchain',
    initialState,
    reducers: {
        updateChain: (state, action) => {
            if(action.payload) {
                state.totalSupply = action.payload.totalSupply
                state.cost = action.payload.cost
                state.account = action.payload.account
                state.presaleCost = action.payload.presaleCost
                state.provider = action.payload.provider
                state.whitelisted = action.payload.whitelisted
            } else {
                state.totalSupply = 0
                state.provider = null
                state.account = null
                state.cost = 0
                state.presaleCost = 0
                state.whitelisted = false
            }
        },
        isLoading: (state, action) => {
            state.loading = action.payload
        }
    }
})

export const { updateChain, isLoading } = blockchainSlice.actions
export default blockchainSlice.reducer