import Banner from '../assets/img/banner-new.png'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { connect, disconnect } from '../Global/Utils/web3';
import { updateChain } from '../Global/Reducers/blockchainSlice';
import { mint } from '../Global/Utils/functions';

const Card = () => {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const [claimingNft, setClaimingNft] = useState(false);
    //const [feedback, setFeedback] = useState(`Click mint to mint your NFT.`);
    const [mintAmount, setMintAmount] = useState(1);

    const connectWeb3 = () => {
        if(blockchain.account) {
            disconnect().then((data) => dispatch(updateChain(data)))
        } else {
            connect().then((data) => dispatch(updateChain(data)))
        }
    }

    const MintAndNotify = async(mintAmount) => {
        mint(mintAmount).then((data) => {
           // alert("Minting..")
        }).catch((err) => {
            console.log(err)
        })
    }


    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
    };
    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > 10) {
            newMintAmount = 10;
        }
        setMintAmount(newMintAmount);
    };
    return (
        <div className="d-flex justify-content-center mb-5">
            <div className="">
                <div className="d-flex justify-content-center my-3">
                    <img className="img-fluid col-xl-7 col-lg-6 col-md-4 col-12" src={Banner} alt="" />
                </div>
                <div className="d-flex justify-content-center mb-3">
                    <div className="text-center">
                        <h2 className="fw-bold coffee">Welcome to African Beauty</h2>
                        <p className="coffee">Connect to your wallet to mint this beautiful NFT</p>
                    </div>
                </div>
                {blockchain.account === null  ? (<div className="d-flex justify-content-center">
                        <button className="rounded coffee-bg px-5 py-2" onClick={(e) => {
                            e.preventDefault();
                            connectWeb3()
                        }}>
                            <small className="vanilla fs-5">Connect</small>
                        </button>
                    </div>) : (
                    <div>
                        <div className='d-flex justify-content-center mb-4'>
                            <div className='mx-4'>
                                <button className='rounded coffee-bg px-5 py-2' disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        decrementMintAmount();
                                    }}><small className='vanilla fs-5'>-</small></button>
                            </div>
                            <div className='mx-4'>
                                <small className='coffee fs-4'>{mintAmount}</small>
                            </div>
                            <div className='mx-4'>
                                <button className='rounded coffee-bg px-5 py-2' disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        incrementMintAmount();
                                    }}><small className='vanilla fs-5'>+</small></button>
                            </div>

                        </div>
                        <div className="d-flex justify-content-center"><button className="rounded coffee-bg px-5 py-2" onClick={(e) => {
                            e.preventDefault()
                            MintAndNotify(mintAmount);
                        }}>
                            <small className="vanilla fs-5">Mint</small>
                        </button></div></div>)}

            </div>
        </div>
    );
}

export default Card;