import { configureStore } from '@reduxjs/toolkit'

import blockchainSlice from '../Reducers/blockchainSlice'

export const store = configureStore({
    reducer: {
        blockchain: blockchainSlice
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: {
                ignoreActions: ['blockchain/updateChain'],
                ignoredPaths: ['blockchain.provider']
            }
        })
})