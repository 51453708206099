import Web3EthContract from 'web3-eth-contract'
import WallectConnectProvider from '@walletconnect/web3-provider'
import Web3Modal from 'web3modal'
import Web3 from 'web3'
import abi from '../Utils/abi.json'



const providerOptions = {
    walletconnect: {
        package: WallectConnectProvider,
        options: {
            // infuraId: '9aa3d95b3bc440fa88ea12eaa4456161',
            rpc: {
                1: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
               // 56: 'https://rpc.ankr.com/bsc'
            },
            chainId: 1,
        }
    }
}
const web3modal = new Web3Modal({
    network: 'mainnet',
    cacheProvider: true,
    providerOptions
})

export const connect = async () => {
    let provider = await web3modal.connect()
    await web3modal.toggleModal()
    provider.on("chainChanged", (chainId) => {
        console.log(chainId)

    })

    if (provider.chainId == 0x1) {


        try {


             Web3EthContract.setProvider(provider)

            const web3 = new Web3(provider);
            const accounts = await web3.eth.getAccounts()
            try {
                  const contract = new Web3EthContract(abi, '0x1738C5C8A36d855e2D5b33A77922303700121745')
                  const totalSupply = await contract.methods.totalSupply().call()
                  const cost = await contract.methods.cost().call()
                 const whitelisted = await contract.methods.whitelisted(accounts[0]).call()
                 const presaleCost = await contract.methods.presalecost().call()
                // await console.log(cost, presaleCost, totalSupply);
                let account = accounts[0]
                return {
                    totalSupply,
                    cost,
                    whitelisted,
                    account,
                    provider,
                    presaleCost
                }
            } catch (err) {
                console.log(err)
            }


        } catch (error) {
            console.log(error);
        }
    } else {
        alert("Please connect to Ethereum Mainnet")
    }



}
export const disconnect = async () => {
    await web3modal.clearCachedProvider();
    //await provider.disconnect()

    return null;
}