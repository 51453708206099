import Web3EthContract from 'web3-eth-contract'
import WallectConnectProvider from '@walletconnect/web3-provider'
import Web3Modal from 'web3modal'
import Web3 from 'web3'
import abi from '../Utils/abi.json'
import { store } from '../Store/store'



export const mint = async (mintAMount) => {
    try {
        //const provider = await web3modal.connect()
        Web3EthContract.setProvider(store.getState().blockchain.provider)

        const web3 = new Web3(store.getState().blockchain.provider);
        const accounts = await web3.eth.getAccounts()
        const contract = new Web3EthContract(abi, '0x1738C5C8A36d855e2D5b33A77922303700121745')

        await contract.methods.mint(accounts[0], mintAMount).send({
            from: accounts[0],
            value: (store.getState().blockchain.cost * mintAMount)
        })
        alert("Your African Beauty NFT Art has been successfully minted")
        
    } catch (err) {
        alert("There was an error in minting your African Beauty NFT art.")
        console.log(err);
    }
}