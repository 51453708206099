// assets
import './App.css';

// components
import Navbar from './components/common/navbar'
import Card from './components/card'
import Footer from './components/common/footer'

const App = () => {

  return (
    <div className="App">
      <div className="theme"></div>
      <div className="content row  p-0 m-0">
        <Navbar />
        <div className="align-self-center">
          <div className="container my-2">
            <Card />
          </div>
        </div>
        <div className="align-self-end p-0">
          <Footer />
        </div>    
      </div>
    </div>
  );
}

export default App;
