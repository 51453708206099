import Brand from '../../assets/img/Logo.png'
import OpenSea from '../../assets/icons/opensea.png'
import { Discord, Instagram, Twitter } from 'react-bootstrap-icons'

const Footer = () => {
    return (
        <footer className="creame-bg w-100 py-3">    
            <div className="container d-flex justify-content-center">
                <div className="d-flex justify-content-center row">
                    <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                        <div className=""> 
                            <img className="col-7 my-4" src={Brand} alt="" />
                            <li className="">
                                <a href="https://africanbeauty.art/about/">
                                    <small className="coffee fw-normal fs-55">About us</small>
                                </a>
                            </li>
                            <li className="">
                                <a href="https://africanbeauty.art/#vision-sec">
                                    <small className="coffee fw-normal fs-55" >Our Vision</small>
                                </a>
                            </li>
                            <li className="">
                                <a href="https://africanbeauty.art/roadmap/">
                                    <small className="coffee fw-normal fs-55">Roadmap</small>
                                </a>
                            </li>
                            <li className="">
                                <a href="https://africanbeauty.art/#team-sec">
                                    <small className="coffee fw-normal fs-55">Our Team</small>
                                </a>
                            </li>
                        </div>
                        
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 col-12 my-auto">
                        <div className="d-flex justify-content-start my-4">
                            <li className="me-2 my-auto">
                                <a className="coffee fs-5" href="https://discord.gg/NJYfVrtbX4">
                                    <Discord className="coffee my-auto me-2 fs-4" />
                                </a>
                            </li>
                            <li className="mx-2 my-auto">
                                <a className="coffee fs-5" href="https://www.instagram.com/africanbeautynft/">
                                    <Instagram className="coffee my-auto mx-2 fs-4" />
                                </a>
                            </li>
                            <li className="mx-2 my-auto">
                                <a className="coffee fs-5" href="https://twitter.com/byafricanbeauty">
                                    <Twitter className="coffee my-auto mx-2 fs-4" />
                                </a>
                            </li>
                            <li className="mx-3 my-auto">
                                <a className="coffee fs-5" href="https://opensea.io/Africanbeautynft">
                                    <img className="img-fluid" style={{width: '26px'}} src={OpenSea} alt="" />
                                </a>
                            </li>
                        </div>
                        <li className="">
                            <a href="https://africanbeauty.art/">
                                <small className="coffee fw-normal fs-55">Contact us</small>
                            </a>
                        </li>
                        <li className="">
                            <a href="https://africanbeauty.art/#faq-sec">
                                <small className="coffee fw-normal fs-55" >Faq</small>
                            </a>
                        </li>
                        <li className="">
                            <a href="https://twitter.com/byafricanbeauty">
                                <small className="coffee fw-normal fs-55">Engage</small>
                            </a>
                        </li>
                        <li className="">
                            <a href="https://africanbeauty.art/license/">
                                <small className="coffee fw-normal fs-55">License</small>
                            </a>
                        </li>
                    </div>
                    {/* <div className="col-xl-12">
                        <small className="d-flex justify-content-center mt-2">Copyright @2022</small>
                    </div> */}
                </div>
            </div>
        </footer>   
    );
}

export default Footer;