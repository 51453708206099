// assets
import Brand from '../../assets/img/Logo.png'
import OpenSea from '../../assets/icons/opensea.png'
import React, { useEffect, useState } from "react";
import { connect, disconnect } from '../../Global/Utils/web3';
import { updateChain } from '../../Global/Reducers/blockchainSlice';
import { Discord, Instagram, Twitter, MenuAppFill } from 'react-bootstrap-icons'
import { useDispatch, useSelector } from "react-redux";

const truncate = (input, len) =>
    input.length > len ? `${input.substring(0, len)}...` : input;


const Navbar = () => {
    const [show, setShow] = useState(false)

    const toggle = () => setShow(!show);
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Click mint to mint your NFT.`);
    const [mintAmount, setMintAmount] = useState(1);

    const connectWeb3 = () => {
        if(blockchain.account) {
            disconnect().then((data) => dispatch(updateChain(data)))
        } else {
            connect().then((data) => dispatch(updateChain(data)))
        }
    }
  

    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container p-2">
                <img className="img-fluid col-xl-2 col-lg-3 col-md-5 col-5 mx-5" src={Brand} alt="" />
                <button className="navbar-toggler" type="button" onClick={toggle}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse mx-5" id="navbarNavDropdown">
                    <ul className="d-flex justify-content-center my-auto">
                        <li className="mx-3 my-auto">
                            <a href="https://africanbeauty.art/">
                                <small className="coffee fw-normal fs-5" >Home</small>
                            </a>
                        </li>
                        <li className="py-2 ">
                            <a href="https://africanbeauty.art/about/">
                                <small className="coffee  fw-normal  fs-55">More About</small>
                            </a>
                        </li>
                        <li className="mx-3 my-auto">
                            <a href="https://africanbeauty.art/roadmap/">
                                <small className="coffee fw-normal fs-5" >Roadmap</small>
                            </a>
                        </li>
                        <li className="mx-3 my-auto">
                            <a href="https://africanbeauty.art/#team-sec">
                                <small className="coffee fw-normal fs-5">Team</small>
                            </a>
                        </li>
                        <li className="mx-3 me-5 my-auto">
                            <a href="https://africanbeauty.art/#faq-sec">
                                <small className="coffee fw-normal fs-5">FAQ</small>
                            </a>
                        </li>
                        <li className="mx-2 my-auto">
                            <a className="coffee fs-5" href="https://discord.gg/NJYfVrtbX4">
                                <Discord className="coffee my-auto mx-2 fs-4" />
                            </a>
                        </li>
                        <li className="mx-2 my-auto">
                            <a className="coffee fs-5" href="https://www.instagram.com/africanbeautynft/">
                                <Instagram className="coffee my-auto mx-2 fs-4" />
                            </a>
                        </li>
                        <li className="mx-2 my-auto">
                            <a className="coffee fs-5" href="https://twitter.com/byafricanbeauty">
                                <Twitter className="coffee my-auto mx-2 fs-4" />
                            </a>
                        </li>
                        <li className="mx-3 my-auto">
                            <a className="coffee fs-5" href="https://opensea.io/Africanbeautynft">
                                <img className="img-fluid" style={{ width: '26px' }} src={OpenSea} alt="" />
                            </a>
                        </li>{blockchain.account === null ? (<button className="rounded coffee-bg mx-2 px-4 py-2" onClick={(e) => {
                                e.preventDefault();
                                connectWeb3()
                            }}>
                                <small className="vanilla fs-6 text-uppercase">Connect</small>
                            </button>) : (<button className="rounded coffee-bg mx-2 px-4 py-2" onClick={(e) => {
                                e.preventDefault();
                                connectWeb3()
                            }}>
                                <small className="vanilla fs-6 text-uppercase">{truncate(blockchain.account, 10)}</small>
                            </button>)}

                    </ul>
                </div>
                <PopMenu
                    show={show}
                    size={'md'}
                    modalContent={
                        <div>
                            <ul className="d-flex justify-content-center flex-column p-4">
                                <li className="my-2 mx-auto">
                                    <a href="https://africanbeauty.art/">
                                        <small className="coffee fw-normal fs-6" >Home</small>
                                    </a>
                                </li>
                                <li className="my-2 mx-auto">
                                    <a href="https://africanbeauty.art/about/">
                                        <small className="coffee fw-normal fs-6">More About</small>
                                    </a>
                                </li>
                                <li className="my-2 mx-auto">
                                    <a href="https://africanbeauty.art/roadmap/">
                                        <small className="coffee fw-normal fs-6" >Roadmap</small>
                                    </a>
                                </li>
                                <li className="my-2 mx-auto">
                                    <a href="https://africanbeauty.art/#team-sec">
                                        <small className="coffee fw-normal fs-6">Team</small>
                                    </a>
                                </li>
                                <li className="my-2 mx-auto">
                                    <a href="https://africanbeauty.art/#faq-sec">
                                        <small className="coffee fw-normal fs-6">FAQ</small>
                                    </a>
                                </li>
                                <div className="d-flex justify-content-center my-2">
                                    <li className="my-2">
                                        <a className="coffee fs-5" href="https://discord.gg/NJYfVrtbX4">
                                            <Discord className="coffee my-auto mx-2 fs-4" />
                                        </a>
                                    </li>
                                    <li className="my-2">
                                        <a className="coffee fs-5" href="https://www.instagram.com/africanbeautynft/">
                                            <Instagram className="coffee my-auto mx-2 fs-4" />
                                        </a>
                                    </li>
                                    <li className="my-2">
                                        <a className="coffee fs-5" href="https://twitter.com/byafricanbeauty">
                                            <Twitter className="coffee my-auto mx-2 fs-4" />
                                        </a>
                                    </li>
                                    <li className="my-2">
                                        <a className="coffee fs-5" href="https://opensea.io/Africanbeautynft">
                                            <img className="col-3 img-fluid" src={OpenSea} alt="" />
                                        </a>
                                    </li>
                                </div>
                                {blockchain.account === null ? (<button className="rounded coffee-bg my-2 px-4 py-2">
                                        <small className="vanilla fs-5" onClick={(e) => {
                                            e.preventDefault();
                                            connectWeb3()
                                        }}>Connect</small>
                                    </button>) : (<button className="rounded coffee-bg mx-2 px-4 py-2" >
                                        <small className="vanilla fs-6 text-uppercase">{truncate(blockchain.account, 10)}</small>
                                    </button>)}

                                <button type="reset" onClick={toggle}>
                                    <small className="coffee fs-5 fw-bold">Close</small>
                                </button>
                            </ul>
                        </div>
                    }
                />

            </div>
        </nav>

    );
}
const PopMenu = ({ show, size, modalContent }) => {
    return (
        <div>
            {show ? (
                <div className="backdrop">
                    <div className={`d-block modal fade show`} id="actionModal" tabIndex={0} aria-labelledby="actionModalLabel" aria-hidden="true">
                        <div className={`modal-dialog modal-dialog-centered modal-${size}`}>
                            <div className="modal-content creame-bg">
                                {modalContent}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (<div></div>)}
        </div>
    );
}

export default Navbar;